import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"

import ProjectHeader from "../../components/project-header/project-header"
import Index from "../../components/index"
import ReadNext from "../../components/read-next/read-next"
import { RoughNotation } from "react-rough-notation"

const ProjectPage = () => {

  const chapters = [
    {
      name: `Overview`,
      scrollTo: 'overview',
    },
    {
      name: 'Brainstorming',
      scrollTo: 'brainstorming',
    },
    {
      name: 'Development',
      scrollTo: 'development',
    },
    {
      name: 'Demo',
      scrollTo: 'demo',
    },
    {
      name: 'Future',
      scrollTo: 'future',
    },
  ];

  const theme = projects['botsAtWork'].themeColor;

  return (
  <>
    <Seo title="Aesop" />
    <ProjectHeader projectObj={projects['botsAtWork']} />
    <section className={`fix-top fix-bottom`} id={`overview`}>
      <Index chapters={chapters} />
        <div className={`content--md-center`}>
          <h2>Overview</h2>
          <p className={`primary lh`}>It's quite cool to have a virtual personal assistant on your phone to open apps, but things get even better when they can literally do your job. While working as a freelance web developer, I often wondered about the possibility of working remotely and just instructing my device about a design instead of worrying about the coding process. I spent some time contemplating how I can make this a reality and got working.</p>
        </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`brainstorming`}>
      <div className={`content--md-center`}>
        <h2 className={`add-margin-bottom`}>Brainstorming</h2>
        <div className={`add-margin-top`}>
            <h6 className={`center`} style={{ color: theme}}>Problem</h6>
            <h4 className={`center`}>How can I make a system that inputs <RoughNotation type={`crossed-off`} show={true} color={theme}>speech</RoughNotation> <RoughNotation type={`underline`} show={true} color={theme} multiline>natural language instructions</RoughNotation> and outputs frontend code for the web?</h4>
          </div>
          <p className={`primary lh add-margin-top`}>My vision was that I would be looking at a live preview of a website, speaking out a modification or instruction and seeing it magically update in front of my eyes. It would be nice if I could hear a response back from the bot (like Siri) if the task was completed or if more information was needed from me.</p>
          <p className={`primary lh`}>The conversation could look like:</p>
          <p className={`primary lh`}>
            <blockquote>
              <div><b>Me</b>: Add an image to the first section</div>
              <div><b>Bot</b>: Sure! What image should I use?</div>
              <div><b>Me</b>: *Pastes an image link from the web*</div>
              <div><b>Bot</b>: Got it, added the image. Anything else?</div>
            </blockquote></p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`development`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Development</h6>
        <h2 className={`add-margin-bottom`}>The Implementation</h2>
        <h4>1. Styling</h4>
        <p className={`primary lh`}>
        First, I knew it would be hard to make the algorithm write both HTML and CSS code, so I decide to make a stylesheet library inspired by Bootstrap. Enter Manuscript.css.
        </p>
        <p className={`primary lh`}>This pre-written stylesheet that saved me time and effort styling each component. It helps anyone add simple and professional UI components to their websites. I spent about a week to make it as customizable as possible and made sure there were several design options and themes to work with.</p>
        <p className={`primary lh`}>This library allowed me to style components through HTML itself by compounding classes that contained pre-written styles.</p>
        <h4 className={`add-margin-top`}>2. Generating Code</h4>
        <p className={`primary lh`}>I wouldn't go through the entire process of coding a bot that understands natural language to write HTML code because I think it'll be long and boring. Here's a quick summary for the same:</p>
        <p className={`primary lh`}>I wrote functions that allowed manipulation of HTML code by considering the code as plain text. These functions required certain parameters to be known (arguments) such as the HTML tag to target, the action to perform, the classes involved, the text to be added, the positioning of the element and more.</p>
        <p className={`primary lh`}></p>
        <h4 className={`add-margin-top`}>3. Understanding intent from natural language and context</h4>
        <p className={`primary lh`}>The next task was to extract the required information from a natural language instruction and supply that to the appropriate function that could perform the intended action. I implemented this using a standard word to vector conversion and hard coded a heuristic approach that used the presence of keywords and their position in the sentence to run a specific function. (I guess it's not the best way to do this, but certainly effective given that I was manually creating a dataset and vocabulary for the bot.)</p>
        <p className={`primary lh`}>Additionally, one could not expect every instruction to contain all the information, so some form of <RoughNotation type={`circle`} show={true} color={theme}>memory</RoughNotation> must be added to the program to remember context through past instructions. This was done by maintaining a stack of operations performed and using previous instructions to determine new actions. This information is also used to reply with relevant questions to obtain missing pieces of information.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`demo`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Demo</h6>
        <h2>The Interaction</h2>
        <p className={`primary lh add-margin-bottom`}>
        If you wish to know more about the process, you can write to me or talk to me about it and I would be happy to share more details. I think the more interesting part is how the interaction with the bot takes place. To show my work designing this interaction, here's a quick demo.
        </p>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube-nocookie.com/embed/N3Iaxm6-EHc"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className={`primary lh add-margin-top`}>Despite the limited vocabulary, the interface was able to create a good looking website and the conversational aspect added to the experience. Small win? I'll take it.</p>
        <p> PS. I really wanted to record the version where instead of typing these instructions, I could speak them out loud and the bot would reply back with its bot voice but I just couldn't manage a proper recording setup to make it look spectacular.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`future`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Future</h6>
        <h2>What's next?</h2>
        <p className={`primary lh`}>
        Even though there's so much more that can be done with this project, I'm quite happy with this initial version. I was able to create a completely functional website using this project which is a great measure of success for me. In the future, I will keep iterating the design and algorithm to improve the interactivity of this project and hopefully make this public so that people who are new to web development can use it to implement their designs.
        </p>
      </div>
    </section>
    <ReadNext projectObj={projects['workaholic']} />
  </>
)}

export default ProjectPage
